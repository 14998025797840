<template>
    <div>
        <div>
            <b-form-group>
                <label>
                    <b>Nom de la collection</b>&nbsp;
                    <span class="text-danger">*</span>
                </label>
                <b-form-input
                    v-model="localCollection.name"
                    :state="isNameValid"
                    trim
                />
                <b-form-invalid-feedback :state="isNameValid">
                    Veuillez saisir le nom de la collection
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group>
                <label>
                    <b>Visibilité</b>&nbsp;<span class="text-danger">*</span>
                </label>
                <b-form-select
                    v-model="localCollection.visibility"
                    :options="visibilityOptions"
                />
            </b-form-group>

            <b-form-group>
                <label>
                    <b>Type de contenu</b>&nbsp;
                    <span class="text-danger">*</span>
                </label>
                <b-form-select
                    v-model="localCollection.content"
                    :options="contentOptions"
                />
            </b-form-group>

            <b-form-group>
                <label>Description</label>
                <b-form-textarea v-model="localCollection.description" trim />
            </b-form-group>

            <b-form-group>
                <label>Type mime restreint</label>
                <b-form-input v-model="localCollection.mime" trim />
            </b-form-group>
        </div>
        <div class="text-center">
            <b-button
                v-if="!isEditMode"
                variant="kalkin-2"
                @click="createCollection()"
            >
                <b-icon-folder-plus /> Créer la collection
            </b-button>
            <b-button v-else variant="kalkin-2" @click="updateCollection()">
                <b-icon-check-circle-fill /> Enregistrer les modifications
            </b-button>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        collection: { type: Object, required: false },
    },

    data: function() {
        let data = {
            isEditMode: false,
            localCollection: {
                name: null,
                visibility: "private",
                content: "media_bank",
                type: "private/media_bank",
                description: null,
                mime: null,
            },
            visibilityOptions: [
                { value: "public", html: "Publique" },
                { value: "project", html: "Projet" },
                { value: "private", html: "Privée" },
                { value: "internal", html: "Interne" },
            ],
            contentOptions: [
                { value: "pin_bank", html: "Banque de pin de base" },
                { value: "icon_bank", html: "Banque d'icônes" },
                { value: "media_bank", html: "Banque de médias" },
                { value: "project", html: "Collection interne projet" },
                { value: "cache", html: "Cache interne" },
            ],
        };

        if (this.collection) {
            data.isEditMode = true;
            data.localCollection = {
                ...data.localCollection,
                ...this.collection,
            };
            data.localCollection.visibility = data.localCollection.type.split(
                "/",
            )[0];
            data.localCollection.content = data.localCollection.type.split(
                "/",
            )[1];
        }
        return data;
    },

    computed: {
        type() {
            return (
                this.localCollection.visibility +
                "/" +
                this.localCollection.content
            );
        },

        isNameValid() {
            if (this.localCollection.name === null) {
                return null;
            } else {
                return this.localCollection.name !== "";
            }
        },

        isValid() {
            return this.isNameValid;
        },
    },

    methods: {
        createCollection: async function() {
            if (this.isValid) {
                await this.$store.dispatch("media/createCollection", {
                    ...this.localCollection,
                    type: this.type,
                });
                this.$router.push({ name: "Medias" });
            } else {
                this.localCollection.name = this.localCollection.name || "";
            }
        },

        updateCollection: async function() {
            if (this.isValid) {
                await this.$store.dispatch("media/updateCollection", {
                    ...this.localCollection,
                    type: this.type,
                });
                this.$emit("saved");
            }
        },
    },
};
</script>
