<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Créer une collection de médias</h1>
            <b-button
                size="sm"
                variant="outline-secondary"
                @click="goListCollections"
            >
                <b-icon-list-ul /> Liste des collections
            </b-button>
        </div>

        <div class="k-page-body">
            <FormCollection />
        </div>
    </div>
</template>
<script>
import FormCollection from "@/components/misc/admin-medias/FormCollection";
export default {
    components: {
        FormCollection,
    },

    methods: {
        goListCollections() {
            this.$router.push({
                name: "Medias",
            });
        },
    },
};
</script>
